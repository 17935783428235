:root {
  --baseFontSize: 10px;
  --baseLineHeight: 10px;
  --bpsmall: 768px;
  --bpmedium: 1024px;
  --bplarge: 1366px;
  --bpxlarge: 1920px;
  --bpxxlarge: 2560px;
  background-color: white;
}

@media screen and (min-width: 500px) {
  html {
    font-size: calc(var(--baseFontSize) * 1);
    line-height: calc(var(--baseLineHeight) * 1.5);
  }
}

@media screen and (min-width: 768px) {
  html {
    font-size: calc(var(--baseFontSize) * 1.2);
    line-height: calc(var(--baseLineHeight) * 1.8);
  }
}

@media screen and (min-width: 900px) {
  html {
    font-size: calc(var(--baseFontSize) * 1.4);
    line-height: calc(var(--baseLineHeight) * 2.1);
  }
}

@media screen and (min-width: 1366px) {
  html {
    font-size: calc(var(--baseFontSize) * 1.6);
    line-height: calc(var(--baseLineHeight) * 2.4);
  }
}

@media screen and (min-width: 1920px) {
  html {
    font-size: calc(var(--baseFontSize) * 1.8);
    line-height: calc(var(--baseLineHeight) * 2.7);
  }
}

@media screen and (min-width: 2560px) {
  html {
    font-size: calc(var(--baseFontSize) * 2);
    line-height: calc(var(--baseLineHeight) * 3);
  }
}

body {
  font-family: 'Poppins', sans-serif;
  margin: 0 !important;
  background-color: #F5F5F5;
  height: 100%;
}

code {
  font-family: 'Poppins', sans-serif;
}

.titles {
  font-family: 'Poppins', sans-serif;
  font-style: normal;
  font-size: 16px;
  line-height: 24px;
}

/* Bold Fonts */
.h1Medium {
  font-family: 'Poppins', sans-serif;
  font-size: 1.5rem;
  line-height: 2rem;
  font-weight: 700;
}

.h2Medium {
  font-family: 'Poppins', sans-serif;
  font-size: 1rem;
  line-height: 1.5rem;
  font-weight: 700;
}

.h3Medium {
  font-family: 'Poppins', sans-serif;
  font-size: 0.875rem;
  line-height: 1.5rem;
  font-weight: 700;
}

.h4Medium {
  font-family: 'Poppins', sans-serif;
  font-size: 0.75rem;
  line-height: 1rem;
  font-weight: 700;
}

.h5Medium {
  font-family: 'Poppins', sans-serif;
  font-weight: 700;
  font-size: 0.625rem;
  line-height: 0.875rem;
}

/* Regular Fonts */
.h1Regular {
  font-family: 'Poppins', sans-serif;
  font-size: 1.125rem;
  line-height: 1.5rem;
  font-weight: 600;
}

.h2Regular {
  font-family: 'Poppins', sans-serif;
  font-size: 1rem;
  line-height: 1.5rem;
  font-weight: 600;
}

.h3Regular {
  font-family: 'Poppins', sans-serif;
  font-size: 0.875rem;
  line-height: 1.5rem;
  font-weight: 600;
}

.h4Regular {
  font-family: 'Poppins', sans-serif;
  font-size: 0.75rem;
  line-height: 1rem;
  font-weight: 600;
}

.h5Regular {
  font-family: 'Poppins', sans-serif;
  font-size: 0.625rem;
  line-height: 0.875rem;
  font-weight: 600;
}

/* width */
::-webkit-scrollbar {
  width: 0.6rem;
  height: 0.5rem;
  border-radius: 61px;
}

/* Track */
::-webkit-scrollbar-track {
  border-radius: 61px;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #c2c1c1;
  border-radius: 61px;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #c2c1c1;
  border-radius: 61px;
}

.MuiBreadcrumbs-separator {
  color: #15284B;
}

.dropdown-style {
  width: 75%;
  height: 30px;
}

.form-control {
  /* width: 15em;  */
  height: 2.5em !important;
  margin-bottom: 0.6rem;
  margin-right: 0.6rem;
  padding: 0.5rem;
  font-weight: 600;
  display: block;
  font-size: .85rem !important;
  line-height: 1.428571429;
  color: var(--dociphi-gray-color)7;
  background-color: #fff;
  background-image: none;
  border: 1px solid #ced4da;
  border-radius: 4px;
  transition: border-color .15s ease-in-out, box-shadow .15s ease-in-out;
  transition: border .2s linear, box-shadow .2s linear;
}

.form-control:focus {
  box-shadow: none !important;
  border: none !important;
  outline: 0 !important;
  box-shadow: inset 0 1px 1px rgb(0 0 0 / 8%), 0 0 8px #66afe999 !important;
}

.bold-text{
  font-weight: 600;
}
